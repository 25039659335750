import React from "react"

import Layout from "../other/layout"
import SEO from "../other/seo"

import Test from "../other/test"
import StaticContent from "../components/AC-StaticPages/StaticContent"

const sellerRegister = () => {
  return (
    <Layout>
      <SEO title="Seller Registration" />
      <StaticContent />
    </Layout>
  )
}

export default sellerRegister
